html, body{
 /*  height : 100vh */
 background-color: #e8eaf7;
}
.App {
  text-align: center;
}

.homeURL {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.bggg{
  background-color: #e8eaf7;
  
}

.MuiListItem-container { 
  border-bottom: inset;
  border-color: lightgray;
}

.logOutButton{

}

.appHeader{
  background-color: #565656;
  color : #d6cac2;
}


.jobInfo{
  background-color: #f5f2f0;
  
}

.leftMargin{
  margin-left : 10px !important;
}

.info{
  
  background-color: #bad2ff}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.MuiGrid-spacing-xs-8 > .MuiGrid-item{
  padding: 1px !important;
  
}

.blue{
  color: #f17407;
}
.sub{
  font-size: 12px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
